import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import PortableText from "../components/portableText";

export const query = graphql`
  query FriendsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawFriendsPageContent(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const sectionRegex = /^(?:h\d|normal)$/gi;
const FriendsPage = ({ data = {} }) => {
  const friendsPageContent = data.site?._rawFriendsPageContent;
  return (
    <>
      <SEO title="Friends of Hatepedia" />
      {friendsPageContent && (
        <div className="prose dark:prose-invert prose-lg md:py-12 media-page-content">
          <PortableText
            blocks={friendsPageContent}
            sectionRegex={sectionRegex}
          />
        </div>
      )}
    </>
  );
};

export default FriendsPage;
